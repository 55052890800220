/* ------------------------- General--------------------------------- */

* {
  scroll-behavior: smooth;
}

body {
  background: linear-gradient(
    75deg,
    rgba(182, 182, 212, 1) 25%,
    rgba(228, 235, 237, 1) 96%
  );
  margin: 0;
  padding-top: 15px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  z-index: 999 !important;
}

a {
  text-decoration: none;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 500;
  color: #c3c8ec;
}

/* initially coming from control panel of the profile */

h4 {
  color: rgb(53, 53, 53);
}

/* initially was for the chartpopup date picker */

Input[type='month'] {
  height: 25px;
  margin: 0 auto;
  width: 50%;
  font-family: Arial, Helvetica, sans-serif;
  text-transform: uppercase;
  outline: none;
  border: 0;
  border-radius: 5px;
  padding: 0 3px;
}

/* for input of type text to have inwriting styling - it was meant only for search window */
Input[type='text'] {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 15px;
  font-weight: 500;
}

/* initially was for the register page */
select {
  appearance: none;
  border-radius: 5px;
  padding: 4px;
  font-size: 18px;
  border: none;
  cursor: pointer;
}
select:focus {
  outline: none;
}

/* textarea */
/* it s made specific for the textarea of 'Share' component in the home page, profile page and project profile feed */

textarea {
  height: 100%;
  width: 75%;
  resize: none;
  outline: none;
  border: none;
  margin-top: 15px;
  font-size: 16px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
}
textarea::-webkit-scrollbar {
  width: 7px;
  background-color: rgb(217, 217, 233);
}
textarea::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgb(80, 77, 77);
  width: 5px;
}
textarea::-webkit-scrollbar-thumb:hover {
  box-shadow: inset 0 0 6px rgb(49, 47, 47);
}
textarea::-webkit-scrollbar-corner {
  border-radius: 5px;
}
textarea::placeholder {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 16px;
  color: gray;
  font-weight: 500;
}

/* Popover styling - down to the compsoing components */
.MuiPopover-paper {
  border-radius: 15px !important;
  overflow: hidden !important;
}

/* ----------------------------Size Specific--------------------------- */

@media (max-width: 600px) {
  body {
    padding: 0px;
  }
  /* scrollbars */
  *::-webkit-scrollbar {
    height: 0;
    width: 0;
    background-color: transparent;
  }
  *::-webkit-scrollbar-thumb {
    height: 0;
    box-shadow: inset 0 0 0 transparent;
    width: 0;
  }

  *::-webkit-scrollbar-corner {
    border-radius: 0;
  }
  *::-webkit-scrollbar-thumb:hover {
    box-shadow: inset 0 0 6px rgb(49, 47, 47);
  }
}
@media (min-width: 600px) {
  /* scrollbars */
  *::-webkit-scrollbar {
    height: 7px;
    width: 7px;
    background-color: rgb(217, 217, 233);
    border-radius: 10px;
  }
  *::-webkit-scrollbar-thumb {
    height: 7px;
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgb(80, 77, 77);
    width: 5px;
  }
  *::-webkit-scrollbar-corner {
    border-radius: 5px;
  }
  *::-webkit-scrollbar-thumb:hover {
    box-shadow: inset 0 0 6px rgb(49, 47, 47);
  }
}

@media all and (min-width: 50px) {
  textarea::placeholder {
    font-size: 0.8em;
  }
  select {
    font-size: 12px;
  }
}
@media all and (min-width: 1200px) {
  textarea::placeholder {
    font-size: inherit;
  }
}

/* adjusting all components size with respect to the right and left bars */

/* Important Notes, the margins and paddings are done from the warpper not the container to keep the values consistent */

@media all and (min-width: 400px) {
  select {
    font-size: 14px;
  }
}

@media all and (min-width: 600px) {
  select {
    font-size: 14.5px;
  }
}
@media all and (min-width: 1000px) {
  select {
    font-size: 18px;
  }
  h3 {
    margin: 15px;
  }
}

@media all and (min-width: 50px) {
  body {
    font-size: 0.1em;
  }
}
@media all and (min-width: 100px) {
  body {
    font-size: 0.2em;
  }
}
@media all and (min-width: 200px) {
  body {
    font-size: 0.4em;
  }
}
@media all and (min-width: 300px) {
  body {
    font-size: 0.6em;
  }
}
@media all and (min-width: 400px) {
  body {
    font-size: 0.6em;
  }
}
@media all and (min-width: 500px) {
  body {
    font-size: 0.8em;
  }
}
@media all and (min-width: 600px) {
  body {
    font-size: 1em;
  }
}
@media all and (min-width: 700px) {
  body {
    font-size: 1.2em;
  }
}

@media all and (min-width: 1000px) {
  body {
    font-size: 0.6em;
  }
}
@media all and (min-width: 1100px) {
  body {
    font-size: 0.6em;
  }
}

@media all and (min-width: 100vw) {
  body {
    font-size: inherit;
  }
}
